import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { motion } from 'framer-motion';
import { isMobile } from 'react-device-detect';
import confetti from 'canvas-confetti';
import logo from '../images/logo192.png';
import telegramIcon from '../images/3.png';
import githubIcon from '../images/avito.png';
import videoSrc from '../video/p3.mp4';

const words = ['keycaps', 'store', 'modding', 'jacquard', 'mouse', 'micriks', 'shop',];

const blink = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
`;

const PageContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const TypewriterContainer = styled.div`
  margin-top: 20px;
  margin-left: ${isMobile ? '1px' : '0'}; // Добавьте отступ слева для мобильных устройств
  display: inline-flex;
  align-items: center;
  position: relative;
  height: 24px;
  opacity: 0;
  animation: ${keyframes`
    0% {
      opacity: 0;
      transform: translateY(-10px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  `} 0.5s ease 0.8s forwards;
`;

const TypewriterText = styled.span`
  color: white;
  font-family: 'Consolas', monospace;
  font-weight: bold;
  font-size: 24px;
  letter-spacing: 1px;
`;

const Cursor = styled.span`
  position: absolute;
  right: -10px;
  top: calc(50% + 2px);
  transform: translateY(-50%);
  width: 2px;
  height: 24px;
  background-color: white;
  opacity: ${props => props.visible ? 1 : 0};
  animation: ${props => props.blink ? blink : 'none'} 0.35s infinite; /* Уменьшена скорость анимации */
  animation-delay: 0.8s; /* Уменьшена задержка */
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center; // Изменено для центрирования на ПК
  width: 100%;
  margin-top: ${isMobile ? '5vh' : '20'};
`;

const VideoBackground = styled.video`
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: translate(-50%, -50%);
  z-index: -1; /* Видео находится за контентом */
  filter: blur(50px); /* Применение эффекта размытия, если нужно */

  @media (max-width: 768px) {
    width: auto;
    height: auto;
    transform: translate(-50%, -50%) scale(1); /* Увеличение видео в 6 раз */
  }
`;

const IconWrapper = styled(motion.div)`
  width: ${props => props.isFhd ? '50px' : '75px'};
  height: ${props => props.isFhd ? '50px' : '75px'};
  margin: ${props => props.isFhd ? '0 10px 0 0' : '0 30px 0 0'};
  opacity: 0.5;
  cursor: pointer;
`;

const Icon = styled.img`
  width: 100%;
  height: 100%;
`;

const iconVariants = {
  hover: {
    scale: 1.1,
    opacity: 1,
    transition: {
      duration: 0.3,
      ease: "easeInOut"
    }
  }
};

const Header = styled.header`
  position: relative;
  justify-content: space-between;
  align-items: center;
  padding: 0.1rem 3rem;
  margin-bottom: 1rem;
  z-index: 2;
  opacity: 0;
  transform: translateY(-20px);
  animation: ${keyframes`
    0% {
      opacity: 0;
      transform: translateY(-20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  `} 0.25s ease 0.75s forwards; /* Уменьшено время задержки */

`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const smoothAnimation = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

const LogoWrapper = styled.div`
  opacity: 0;
  animation: ${keyframes`
    0% {
      opacity: 0;
      transform: scale(0.8);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  `} 0.25s ease 0.8s forwards; 
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  margin-top: 20px;
  margin-right: 20px;
  margin-left: ${isMobile ? '-20px' : '0'}; // Добавьте отступ слева для мобильных устройств

  &:hover img {
    transform: scale(1.1);
  }

  &:hover::after {
    opacity: 0.7;
    animation: ${smoothAnimation} 6s infinite linear;
    transform: scale(0.8);
  }

  &::after {
    content: "";
    position: absolute;
    top: -20px;
    left: -20px;
    width: 100px;
    height: 100px;
    filter: blur(40px);
    opacity: 0;
    transition: opacity 0.3s ease, transform 0.3s ease;
    background-size: 400% 400%;
    z-index: -1;
  }
`;

const Logo = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
  transition: transform 0.1s ease-in-out;
  z-index: 0;
`;

const Main = styled.main`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${isMobile ? 'center' : 'center'};
  text-align: ${isMobile ? 'center' : 'center'};
  padding: 2rem;
  margin-left: ${isMobile ? '0' : '0'};
  margin-top: ${isMobile ? '0' : '0'};
  min-height: ${isMobile ? 'calc(100vh - 250px)' : '70vh'}; // Изменено для ПК
  padding-top: ${isMobile ? '10px' : '0'};
  z-index: 1;
  opacity: 0;
  animation: 
    ${keyframes`
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    `} 0.25s ease 0.4s forwards;
`;

const Title = styled.h1`
  color: white;
  font-size: ${isMobile ? '2vh' : '3.5rem'}; // Увеличен размер для ПК
  margin-bottom: ${isMobile ? '1vh' : '2rem'};
  margin-top: ${isMobile ? '5vh' : '0'};
  opacity: 0;
  animation: 
    ${keyframes`
      0% {
        opacity: 0;
        transform: translateY(20px);
      }
      100% {
        opacity: 1;
        transform: translateY(0);
      }
    `} 0.25s ease 0.5s forwards;
`;

const Description = styled.p`
  color: #ccc;
  max-width: ${isMobile ? '90%' : '800px'}; // Увеличена максимальная ширина для ПК
  margin-bottom: ${isMobile ? '1rem' : '3rem'}; // Увеличен отступ для ПК
  margin-top: ${isMobile ? '1.5rem' : '2rem'};
  font-size: ${isMobile ? '0.9rem' : '1.2rem'}; // Увеличен размер шрифта для ПК
  opacity: 0;
  text-align: ${isMobile ? 'center' : 'center'};
  animation: 
    ${keyframes`
      0% {
        opacity: 0;
        transform: translateY(20px);
      }
      100% {
        opacity: 1;
        transform: translateY(0);
      }
    `} 0.25s ease 0.6s forwards;
`;

const DownloadText = styled.span`
  font-family: 'Consolas', monospace;
  font-size: 2.7rem;
`;


const Typewriter = () => {
  const [text, setText] = useState('');
  const [wordIndex, setWordIndex] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [cursorVisible, setCursorVisible] = useState(false);
  const [cursorBlink, setCursorBlink] = useState(false);
  const [lastTypedTime, setLastTypedTime] = useState(Date.now());
  const [isStarted, setIsStarted] = useState(false);

  useEffect(() => {
    const startDelay = setTimeout(() => {
      setIsStarted(true);
    }, 3600); // 3.6 секунды задержки перед началом анимации

    return () => clearTimeout(startDelay);
  }, []);

  useEffect(() => {
    const cursorDelay = setTimeout(() => {
      setCursorVisible(true);
    }, 3200); // 3.2 секунды задержки
  
    return () => clearTimeout(cursorDelay);
  }, []);

  useEffect(() => {
    if (!isStarted) return;

    const currentWord = words[wordIndex];
    
    const typeSpeed = () => Math.random() * (150 - 50) + 50;
    const errorProbability = 0.1;
    
    const timer = setTimeout(() => {
      const now = Date.now();
      if (now - lastTypedTime > 1000) {
        setCursorBlink(true);
      } else {
        setCursorBlink(false);
        setCursorVisible(true);
      }

      if (!isDeleting && text.length < currentWord.length) {
        let nextChar = currentWord[text.length];
        if (Math.random() < errorProbability) {
          // Simulate typo
          nextChar = 'abcdefghijklmnopqrstuvwxyz'[Math.floor(Math.random() * 26)];
        }
        setText(text + nextChar);
        setLastTypedTime(now);
      } else if (isDeleting && text.length > 0) {
        setText(text.slice(0, -1));
        setLastTypedTime(now);
      } else if (text.length === currentWord.length) {
        setIsDeleting(true);
        return; // Используем return вместо setTimeout
      } else if (text.length === 0) {
        setIsDeleting(false);
        setWordIndex((prevIndex) => (prevIndex + 1) % words.length);
        return; // Используем return вместо setTimeout
      }
    }, typeSpeed());

    return () => clearTimeout(timer);
  }, [text, isDeleting, wordIndex, lastTypedTime, isStarted]);

  return (
    <TypewriterContainer>
      <TypewriterText>{text}</TypewriterText>
      <Cursor visible={cursorVisible} blink={cursorBlink} />
    </TypewriterContainer>
  );
};

function MainPage() {
  const [isFhd, setIsFhd] = useState(false);

  useEffect(() => {
    // Проверяем разрешение экрана при монтировании компонента
    const handleResize = () => {
      setIsFhd(window.innerWidth <= 1920); // Определяем, является ли экран FHD или 2K
    };
    window.addEventListener('resize', handleResize);
    handleResize(); // Вызываем функцию один раз при монтировании
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleConfetti = () => {
    confetti({
      particleCount: 80,
      spread: 90,
      origin: { y: 0.55, x: isMobile ? 0.5 : 0.5 } // Центр экрана для мобильных устройств
    });
  };

  const handleIconClick = (url) => {
    window.open(url, "_blank");
  };

  const handleMainPageClick = () => {
    window.location.href = "https://t.me/kovryspb";  
  };

  return (
    <PageContainer>
      <VideoBackground autoPlay loop muted>
        <source src={videoSrc} type="video/mp4" />
      </VideoBackground>

        <Header>
          <LogoContainer>
            <LogoWrapper>
              <Logo src={logo} alt="купи ковёр, гад." onClick={handleMainPageClick}/>
            </LogoWrapper>
            <Typewriter />
          </LogoContainer>
        </Header>
      
      <Main>
        <Title>
          <DownloadText onClick={handleConfetti}>
            Keyding Store
          </DownloadText>
        </Title>
        <Description isFhd={isFhd}>
          Cобираю и занимаюсь мoддингом клавиатур, продажей ковров для мыши. Mожeм подoбрать вам идeaльный для вac вapиaнт вceго за пару чacов. Mогу cориентиpoвать вac пo выбopу пoд ваши вкуcы.
        </Description>
        <IconContainer>
          <IconWrapper
            isFhd={isFhd}
            variants={iconVariants}
            whileHover="hover"
            onClick={() => handleIconClick("https://t.me/kovryspb")}
          >
            <Icon src={telegramIcon} alt="Telegram" />
          </IconWrapper>
          <IconWrapper
            isFhd={isFhd}
            variants={iconVariants}
            whileHover="hover"
            onClick={() => handleIconClick("https://www.avito.ru/sankt-peterburg/tovary_dlya_kompyutera/kastomye_kovriki_dlya_myshi_4267065072")}
          >
            <Icon src={githubIcon} alt="GitHub" />
          </IconWrapper>
        </IconContainer>
      </Main>
    </PageContainer>
  );
}

export default MainPage;